import { useState, useRef } from 'react';
import axios from 'axios';
import mixpanel from '@/lib/mixpanel';
import imageCompression from 'browser-image-compression';

type Line = {
  text: string;
  x: number;
  y: number;
};

type UploadImageResponse = {
  url: string;
};

const useMemeActions = (
  memeImages: string[],
  currentIndex: number,
  topText: string,
  bottomText: string,
  userAddress: string | null,
  showGif: boolean,
) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const memeRef = useRef<HTMLDivElement>(null);

  const calculateFontSize = (imageHeight: number) => {
    return Math.round(imageHeight / 14);
  };

  const wrapText = (
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number,
  ): Line[] => {
    const words = text.split(' ');
    let line = '';
    let testLine = '';
    const lineArray: Line[] = [];
    const maxCharsPerLine = 35;

    for (let n = 0; n < words.length; n++) {
      testLine += `${words[n]} `;
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        lineArray.push({ text: line.trim(), x, y });
        y += lineHeight;
        line = `${words[n]} `;
        testLine = `${words[n]} `;
      } else {
        line = testLine;
      }

      if (words[n].length > maxCharsPerLine) {
        let subWord = words[n];
        while (subWord.length > maxCharsPerLine) {
          const splitText = subWord.substring(0, maxCharsPerLine);
          lineArray.push({ text: splitText, x, y });
          y += lineHeight;
          subWord = subWord.substring(maxCharsPerLine);
        }
        lineArray.push({ text: subWord, x, y });
        y += lineHeight;
        line = '';
        testLine = '';
      }
    }
    if (line.trim() !== '') {
      lineArray.push({ text: line.trim(), x, y });
    }
    return lineArray;
  };

  const getCanvasBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');
    });
  };

  const uploadFile = async (
    formData: FormData,
    endpoint: string,
  ): Promise<string> => {
    const response = await axios.post<UploadImageResponse>(endpoint, formData);
    return response.data.url;
  };

  const uploadImage = async (
    blob: Blob,
    memeName: string | undefined,
    status: string,
  ): Promise<string> => {
    const formData = new FormData();
    formData.append('image', blob, 'meme.png');
    if (userAddress) {
      formData.append('userAddress', userAddress);
    }
    if (memeName) {
      formData.append('memeName', memeName);
    }
    formData.append('status', status);

    const response = await axios.post<UploadImageResponse>('/api/upload-image', formData);
    return response.data.url;
  };

  const drawTextWithLetterSpacing = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    spacing: number,
  ) => {
    const characters = text.split('');
    const totalWidth = characters.reduce(
      (acc, char) => acc + context.measureText(char).width,
      0,
    ) + spacing * (characters.length - 1);
    let currentX = x - totalWidth / 2;

    characters.forEach((char) => {
      context.strokeText(char, currentX, y);
      context.fillText(char, currentX, y);
      currentX += context.measureText(char).width + spacing;
    });
  };

  const createMemeCanvas = async (): Promise<HTMLCanvasElement> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = memeImages[currentIndex];
      const banner = new Image();
      banner.src = '/logo/meme-banner.png';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const bannerHeight = 50;
        canvas.width = image.width;
        canvas.height = image.height + bannerHeight;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          const fontSize = calculateFontSize(image.height);
          const maxWidth = canvas.width * 0.9;
          const lineHeight = fontSize + 15;
          ctx.drawImage(image, 0, 0, canvas.width, image.height);
          ctx.font = `900 ${fontSize}px Arial`;
          ctx.fillStyle = 'white';
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 6;

          if (topText) {
            const topLines = wrapText(ctx, topText, canvas.width / 2, fontSize, maxWidth, lineHeight);
            topLines.forEach((line) => {
              drawTextWithLetterSpacing(ctx, line.text, line.x, line.y, 2);
            });
          }

          if (bottomText) {
            const bottomLines = wrapText(ctx, bottomText, canvas.width / 2, image.height - fontSize, maxWidth, lineHeight);
            const totalTextHeight = bottomLines.length * lineHeight;
            const bottomOffset = 50;
            const initialY = image.height - totalTextHeight + bottomOffset;
            bottomLines.forEach((line, index) => {
              const y = initialY + index * lineHeight;
              drawTextWithLetterSpacing(ctx, line.text, line.x, y, 2);
            });
          }

          banner.onload = () => {
            ctx.drawImage(banner, 0, image.height, canvas.width, bannerHeight);
            resolve(canvas);
          };
        } else {
          resolve(canvas);
        }
      };
    });
  };

  const downloadFile = async (url: string, filename: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const localUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = filename;
    link.href = localUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(localUrl);
  };

  const handleGifSave = async () => {
    const gifPath = `/memes/animated/${memeImages[currentIndex].split('/').pop()?.replace('.webp', '.gif')}`;
    if (gifPath) {
      const gifBlob = await fetch(gifPath).then(r => r.blob());
      const formData = new FormData();
      formData.append('gif', gifBlob);
      if (userAddress) formData.append('userAddress', userAddress);
      const memeName = memeImages[currentIndex].split('/').pop();
      if (memeName) formData.append('memeName', memeName);
      const gifUrl = await uploadFile(formData, '/api/upload-gif');
      await downloadFile(gifUrl, 'meme.gif');
    }
  };

  const handleImageSave = async () => {
    if (!memeRef.current) return;
    const canvas = await createMemeCanvas();
    const blob = await getCanvasBlob(canvas);
    if (blob) {
      // Compress the image before uploading
      const compressedFile = await imageCompression(new File([blob], "meme.png", { type: "image/png" }), {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      });
  
      const formData = new FormData();
      formData.append('image', compressedFile, 'meme.png');
      if (userAddress) formData.append('userAddress', userAddress);
      const memeName = memeImages[currentIndex].split('/').pop();
      if (memeName) formData.append('memeName', memeName);
      
      const imageUrl = await uploadFile(formData, '/api/upload-image');
      await downloadFile(imageUrl, 'meme.png');
    }
  };

  const saveMeme = async () => {
    setIsSaving(true);
    try {
      if (showGif) {
        await handleGifSave();
      } else {
        await handleImageSave();
      }
      mixpanel.track('Meme Generator - Meme Saved');
    } catch (error) {
      console.error('Error saving meme:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const shareOnX = async () => {
    setIsSharing(true);
    try {
      const canvas = await createMemeCanvas();
      const blob = await getCanvasBlob(canvas);
      if (blob) {
        const memeName = memeImages[currentIndex].split('/').pop();
        const imageUrl = await uploadImage(blob, memeName, 'shared');
        const imageId = imageUrl.split('/').pop();
        if (!imageId) throw new Error('Failed to extract image ID from URL');
        const memePageUrl = `https://chadai.org/meme/${encodeURIComponent(imageId)}`;
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(memePageUrl)}&text=${encodeURIComponent('Founded by @chadAltmeme, @ChadAI is the creator of the ChadGPT Degenerative AI model powering Chad Bot, your AI trading fren.')}`;
        window.open(twitterUrl, '_blank');
        mixpanel.track('Meme Generator - Meme Shared');
      }
    } catch (error) {
      console.error('Error sharing meme:', error);
    } finally {
      setIsSharing(false);
    }
  };

  const createFinalMeme = async (): Promise<string> => {
    const canvas = await createMemeCanvas();
    const blob = await getCanvasBlob(canvas);
    if (blob) {
      return URL.createObjectURL(blob);
    }
    return '';
  };

  return {
    saveMeme,
    shareOnX,
    isSaving,
    isSharing,
    memeRef,
    createFinalMeme,
  };
};

export default useMemeActions;
