// src/app/components/ai-door-background/DoorComponent.tsx

import React from 'react';
import useMediaQuery from '@/utils/use-media-query';
import styles from './DoorComponent.module.scss';

const DoorComponent: React.FC<{
  isKillAIPressed: boolean;
  isKillAIReleased: boolean;
}> = ({ isKillAIPressed, isKillAIReleased }) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  return (
    <div
      className={`${styles.door} ${isKillAIPressed ? styles['door--close'] : ''} ${isKillAIReleased ? styles['door--open'] : ''}`}
    >
      <img
        src={
          isMobile ? '/doors/door-mobile-left.webp' : '/doors/door-left.webp'
        }
        alt='Left Door'
        className={`${styles['left-door']} ${isKillAIPressed ? styles['left-door--close'] : ''}`}
      />
      <img
        src={
          isMobile ? '/doors/door-mobile-right.webp' : '/doors/door-right.webp'
        }
        alt='Right Door'
        className={`${styles['right-door']} ${isKillAIPressed ? styles['right-door--close'] : ''}`}
      />
    </div>
  );
};

export default DoorComponent;
