// src/hooks/use-dom-content-loaded.ts

import { useEffect } from 'react';

const useDOMContentLoaded = (callback: () => void) => {
  useEffect(() => {
    const handleDOMContentLoaded = () => {
      callback();
    };

    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      handleDOMContentLoaded();
    } else {
      document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
    };
  }, [callback]);
};

export default useDOMContentLoaded;
