'use client';

import React, { useEffect, useRef } from 'react';
import { useDoorContext } from '@/context/DoorContext';
import styles from './AIDoorBackground.module.scss';

interface AIDoorBackgroundProps {
  className?: string;
}

const AIDoorBackground: React.FC<AIDoorBackgroundProps> = ({ className }) => {
  const { isDoorOpen, isKillAIPressed, isKillAIReleased, setIsDoorOpen } =
    useDoorContext();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (isKillAIReleased) {
      setIsDoorOpen(true);
      if (videoRef.current) {
        void videoRef.current.play();
        videoRef.current.playbackRate = 0.5; // Slow down the video playback
      }
    } else if (isKillAIPressed) {
      setIsDoorOpen(false);
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isKillAIPressed, isKillAIReleased, setIsDoorOpen]);

  const handleVideoEnded = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video when it reaches the end
    }
  };

  useEffect(() => {
    const handleVideoError = () => {
      if (videoRef.current) {
        videoRef.current.style.display = 'none';
        const img = document.createElement('img');
        img.src = '/backgrounds/kill-ai-background.jpg';
        img.className = styles['ai-door-background__fallback-image'];
        videoRef.current.parentNode?.appendChild(img);
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('error', handleVideoError);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('error', handleVideoError);
      }
    };
  }, []);

  return (
    <div
      className={`${styles['ai-door-background__background']} ${isDoorOpen ? styles['ai-door-background__background--open'] : ''} ${className}`}
    >
      <video
        ref={videoRef}
        className={styles['ai-door-background__video']}
        src='/backgrounds/kill-ai-background.mov'
        autoPlay
        muted
        onEnded={handleVideoEnded}
        poster='/backgrounds/kill-ai-background.jpg'
      />
    </div>
  );
};

export default AIDoorBackground;
