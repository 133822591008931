import { useRef } from 'react';
import useSounds from '@/hooks/useSounds';
import { useDoorContext } from '@/context/DoorContext';

const useKillAI = (setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>) => {
  const { isKillAIPressed, setIsKillAIPressed, setIsKillAIReleased } = useDoorContext();
  const { playSound } = useSounds();
  const killAISoundRef = useRef<HTMLAudioElement>(null);
  const killAISound3Ref = useRef<HTMLAudioElement>(null);

  const handleKillAIClick = () => {
    playSound('killAI');

    setIsKillAIPressed(true);
    setIsKillAIReleased(false);

    if (killAISoundRef.current) {
      killAISoundRef.current.addEventListener('ended', () => {
        playSound('killAI3');

        setTimeout(() => {
          if (killAISound3Ref.current) {
            killAISound3Ref.current.pause();
            killAISound3Ref.current.currentTime = 0;
          }
        }, 6000); // Stop after 6 seconds
      });
    }

    setTimeout(() => {
      setIsKillAIPressed(false);
      setIsKillAIReleased(true);
      setIsModalVisible(true); // Show the modal after the AI is killed
    }, 8400); // Total delay = 5.4s close + 3s wait before open
  };

  return { handleKillAIClick, isKillAIPressed, killAISoundRef };
};

export default useKillAI;
