/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';
import styles from './ComingSoon.module.scss';

const ComingSoon: React.FC = () => {
  const [startText, setStartText] = useState(
    'Press the power button <br /> or space bar to turn on',
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1024px)');
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setStartText('Press the power button <br /> or tap to turn on');
      } else {
        setStartText('Press the power button <br /> or space bar to turn on');
      }
    };

    // Set initial value
    if (mediaQuery.matches) {
      setStartText('Press the power button <br /> or touch to turn on');
    }

    // Add event listener
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Clean up event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div className={styles['coming-soon__container']}>
      <div className={styles['coming-soon__overlay']}></div>
      <div
        className={`${styles['coming-soon__overlay']} ${styles['coming-soon__overlay--glitch']}`}
      ></div>
      <div
        className={styles['coming-soon__version']}
        data-text='// v1.25 CRYPTO BROS SYNDICATE 2K24'
      >
        // ChadOS v0.69 ChadAI Foundation
      </div>
      <div
        className={styles['coming-soon__start-text']}
        dangerouslySetInnerHTML={{ __html: startText }}
      ></div>
      <div className={styles['coming-soon__backed-by']} data-text='BACKED BY:'>
        <div
          className={styles['coming-soon__backed-by-text']}
          data-text='BACKED BY:'
        >
          BACKED BY:
        </div>
        <div
          className={`${styles['coming-soon__logos']} ${styles['coming-soon__logos--first-row']}`}
        >
          <div>
            <img src='/logo/chadscale.webp' alt='Chadscale' />
          </div>
          <div>
            <img src='/logo/multichad-capital.webp' alt='Multichad Capital' />
          </div>
          <div>
            <img src='/logo/chadbase-ventures.webp' alt='Chadbase Ventures' />
          </div>
        </div>
        <div
          className={`${styles['coming-soon__logos']} ${styles['coming-soon__logos--second-row']}`}
        >
          <div>
            <img src='/logo/69-crypto.webp' alt='A69z Crypto' />
          </div>
          <div>
            <img src='/logo/polychad-capital.webp' alt='Polychad Capital' />
          </div>
          <div>
            <img
              src='/logo/digital-currency-chad.webp'
              alt='Digital Currency Chads'
            />
          </div>
          <div>
            <img src='/logo/3-chads-capital.webp' alt='3 Chads Capital' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
