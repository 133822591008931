// src/app/components/center/ChatBotButtons.tsx

import React from 'react';
import styles from './Center.module.scss';
import WhatsappBot from '@/views/chadbot/whatsapp/WhatsappBot';

interface ChatBotButtonsProps {
  handleButtonPress: (
    action: () => void,
    buttonName: string,
  ) => (
    event: React.PointerEvent | React.TouchEvent | React.MouseEvent,
  ) => void;
  handleMouseDown: (button: string, content: React.ReactNode) => void;
  icons: {
    whatsappIcon: string;
    telegramIcon: string;
    discordIcon: string;
  };
}

const ChatBotButtons: React.FC<ChatBotButtonsProps> = ({
  handleButtonPress,
  handleMouseDown,
  icons,
}) => {
  return (
    <div className={styles['center__chadbot-row']}>
      <img
        src='/labels/chad-bot-label-3.webp'
        className={styles['center__chadbot-row--label']}
        alt=''
      />
      <button
        className={`${styles['center__chadbot-button']} ${styles['center__chadbot-button--whatsapp']}`}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('whatsapp', <WhatsappBot />),
          'whatsapp',
        )}
      >
        <img src={icons.whatsappIcon} alt='WhatsApp' draggable='false' />
      </button>
      <button
        className={`${styles['center__chadbot-button']} ${styles['center__chadbot-button--telegram']}`}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('telegram', <WhatsappBot />),
          'telegram',
        )}
      >
        <img src={icons.telegramIcon} alt='Telegram' draggable='false' />
      </button>
      <button
        className={`${styles['center__chadbot-button']} ${styles['center__chadbot-button--discord']}`}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('discord', <WhatsappBot />),
          'discord',
        )}
      >
        <img src={icons.discordIcon} alt='Discord' draggable='false' />
      </button>
    </div>
  );
};

export default ChatBotButtons;
