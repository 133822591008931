// context/DoorContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

interface DoorContextProps {
  isKillAIPressed: boolean;
  setIsKillAIPressed: Dispatch<SetStateAction<boolean>>;
  isKillAIReleased: boolean;
  setIsKillAIReleased: Dispatch<SetStateAction<boolean>>;
  isDoorOpen: boolean;
  setIsDoorOpen: Dispatch<SetStateAction<boolean>>;
}

const DoorContext = createContext<DoorContextProps | undefined>(undefined);

export const DoorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isKillAIPressed, setIsKillAIPressed] = useState(false);
  const [isKillAIReleased, setIsKillAIReleased] = useState(false);
  const [isDoorOpen, setIsDoorOpen] = useState(false);

  return (
    <DoorContext.Provider
      value={{
        isKillAIPressed,
        setIsKillAIPressed,
        isKillAIReleased,
        setIsKillAIReleased,
        isDoorOpen,
        setIsDoorOpen,
      }}
    >
      {children}
    </DoorContext.Provider>
  );
};

export const useDoorContext = () => {
  const context = useContext(DoorContext);
  if (context === undefined) {
    throw new Error('useDoorContext must be used within a DoorProvider');
  }
  return context;
};
