"use client";

import { useState, useEffect, useCallback } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import axios from "axios";
import { getCookie } from "@/utils/cookies";
import useSounds from "@/hooks/useSounds";
import { MixpanelTrackEvent } from "app/api/mixpanel/types";
import useMixpanelTracking from './useMixpanelTracking';
import { getIp } from "@/utils/getIp";

enum WalletButtonImage {
  Connect = "/terminal-keys/wallet-connect-rest.webp",
  Disconnect = "/terminal-keys/wallet-disconnect-pressed.webp"
}

const useWalletHandling = (isPowerOn: boolean) => {
  const { open } = useWeb3Modal();
  const { status, address } = useAccount();
  const [walletButtonImage, setWalletButtonImage] = useState<WalletButtonImage>(WalletButtonImage.Connect);
  const { playSound } = useSounds();
  const [userIp, setUserIp] = useState<string | null>(null);
  const { track, updateProfile } = useMixpanelTracking();
  const [connectionCount, setConnectionCount] = useState(0);

  useEffect(() => {
    const getUserIp = async () => {
      const ip = await getIp();
      setUserIp(ip);
    };

    void getUserIp();
  }, []);

  const handleWalletClick = useCallback(async () => {
    try {
      await open();
      const trackEvent: MixpanelTrackEvent = {
        event: "Wallet Connect Pressed",
        properties: {},
      };
      await axios.post("/api/mixpanel/track", trackEvent);
    } catch (error) {
      console.error("Error opening wallet:", error);
    }
  }, [open]);

  const handleWalletButtonPress = useCallback((action: () => void) => {
    if (isPowerOn) {
      void handleWalletClick();
      action();
    }
  }, [isPowerOn, handleWalletClick]);

  useEffect(() => {
    setWalletButtonImage(status === "connected" ? WalletButtonImage.Disconnect : WalletButtonImage.Connect);
    if (status === 'connected') {
      setConnectionCount(prev => prev + 1);
    }
  }, [status]);

  useEffect(() => {
    const identifyUser = async () => {
      let mixpanelId = getCookie("mixpanel_id") || null;

      if (!mixpanelId) {
        try {
          const response = await axios.get("/api/mixpanel/identify");
          mixpanelId = response.data.mixpanelId;

          await updateProfile({
            wallet_address: address,
            last_wallet_connect: new Date().toISOString(),
            is_wallet_connected: true,
            ip: userIp || '',
          });
        } catch (error) {
          console.error("Error identifying user:", error);
        }
      }

      if (mixpanelId && status === "connected" && address) {
        try {
          await track("Wallet Connected", { wallet_address: address, ip: userIp || '' });
          await track("$create_alias", { alias: address, ip: userIp || '' });
        } catch (error) {
          console.error("Error tracking wallet connection:", error);
        }
      }
    };

    if (userIp) {
      void identifyUser();
    }
  }, [status, address, track, updateProfile, userIp]);

  return {
    walletButtonImage,
    handleWalletButtonPress,
    playSound,
    address,
    status,
    connectionCount
  };
};

export default useWalletHandling;