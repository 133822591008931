import { useEffect, useCallback } from 'react';
import { useUIContext } from '@/context/UIContext';
import useSounds from '@/hooks/useSounds';
import styles from '@/components/center/Center.module.scss';

const useKeyboardAndTouchEvents = () => {
  const { isPowerOn, togglePowerButton } = useUIContext();
  const { playSound } = useSounds();

  const playAppropriateSound = useCallback((type: string) => {
    if (type === 'space' || type === 'touch') {
      playSound('buttonPress');
    }
  }, [playSound]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Space' && !isPowerOn) {
        event.preventDefault();
        togglePowerButton();
        playAppropriateSound('space');
      }
    },
    [isPowerOn, togglePowerButton, playAppropriateSound],
  );

  const handleTouchStart = useCallback(
    (event: TouchEvent) => {
      if (!isPowerOn) {
        event.preventDefault();
        togglePowerButton();
        playAppropriateSound('touch');
      }
    },
    [isPowerOn, togglePowerButton, playAppropriateSound],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const tvFrame = document.querySelector(`.${styles['center__tv-frame']}`);
    if (tvFrame) {
      tvFrame.addEventListener('touchstart', handleTouchStart as EventListener);
    }

    return () => {
      if (tvFrame) {
        tvFrame.removeEventListener(
          'touchstart',
          handleTouchStart as EventListener,
        );
      }
    };
  }, [handleTouchStart]);

  return null;
};

export default useKeyboardAndTouchEvents;