'use client';
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './Staking.module.scss';
import TerminalHeader from '../terminal-header/TerminalHeader';
import { useUIContext } from '@/context/UIContext';
import NFT from '../nft/Nft';

const Staking: React.FC = () => {
  const { setActiveButton, setNftIcon, setStakingIcon, setScreenContent } =
    useUIContext();

  const handleNftLinkClick = () => {
    setScreenContent(<NFT />);
    setActiveButton('nft');
    setNftIcon('/terminal-keys/nft-pressed.webp');
    setStakingIcon('/terminal-keys/staking-rest.webp');
  };

  return (
    <div className={styles.staking}>
      <TerminalHeader />
      <h2 className={styles['staking__title']}></h2>
      <p className={styles['staking__paragraph']}>
        {`> `}Want early access to earn $CHADAI for training the Chad Language
        Model and keep its 420 trillion parameters up to date with the latest
        real-time social sentiment and market data?
      </p>
      <p className={styles['staking__paragraph']}>Here's your checklist:</p>
      <ul className={styles['staking__list']}>
        <li>
          1. Install Chad Bot
          <span className={styles['staking__list-item-detail']}>
            {' '}
            (requires ChadOS Floppy Disk NFT)
          </span>
        </li>
        <li>2. Hold a WGMIDIA NFT</li>
        <li>3. Stake $CHADAI</li>
      </ul>
      <p className={styles['staking__paragraph']}>
        {`> `}Only the most loyal community contributors will gain the right to
        flex the WGMIDIA NFTs, unlocking additional rewards like early access to
        staking and earning $GPU
      </p>
      <p className={styles['staking__paragraph']}>
        {`> `}Find out how to get your early access NFTs{' '}
        <a
          href='#NFT'
          className={styles['staking__link']}
          onClick={handleNftLinkClick}
        >
          HERE
        </a>
      </p>
    </div>
  );
};

export default Staking;
