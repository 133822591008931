// src/app/page.tsx

'use client';
import React, { useEffect, useState } from 'react';
import Header from '@/components/header/Header';
import Center from '@/components/center/Center';
import Footer from '@/components/footer/Footer';
import Loader from '@/components/loader/Loader';
import AIDoorBackground from '@/components/ai-door-background/AIDoorBackground';
import { DoorProvider, useDoorContext } from '@/context/DoorContext';
import GlassMorphicModal from '@/components/modal/GlassMorphicModal';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';
import { getIp } from '@/utils/getIp';
import useTrackPageLoad from '@/hooks/use-track-page-load';
import useDOMContentLoaded from '@/hooks/use-dom-content-loaded';
import useDoorState from '@/hooks/use-door-state';
import DoorComponent from '@/components/ai-door-background/DoorComponent';
import styles from './page.module.scss';

const PageContent: React.FC = () => {
  const [, setDomContentLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const { isKillAIPressed, isKillAIReleased, isDoorOpen } = useDoorContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userIp, setUserIp] = useState<string | null>(null);
  const { track, updateProfile } = useMixpanelTracking();

  useEffect(() => {
    const getUserIp = async () => {
      const ip = await getIp();
      setUserIp(ip);
    };
    void getUserIp();
  }, []);

  useTrackPageLoad(userIp, track, updateProfile);
  useDOMContentLoaded(() => setDomContentLoaded(true));
  useDoorState(setIsModalVisible);

  const handleAnimationComplete = () => {
    setShowLoader(false);
  };

  return (
    <div className={styles['page__container']}>
      {showLoader && <Loader onAnimationComplete={handleAnimationComplete} />}
      {isDoorOpen && (
        <AIDoorBackground className={styles['page__door--open']} />
      )}
      <main className={styles['page__main']}>
        <Header />
        <Center setIsModalVisible={setIsModalVisible} />
        <Footer />
      </main>
      <DoorComponent
        isKillAIPressed={isKillAIPressed}
        isKillAIReleased={isKillAIReleased}
      />
      <GlassMorphicModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
};

const Page: React.FC = () => {
  return (
    <DoorProvider>
      <PageContent />
    </DoorProvider>
  );
};

export default Page;
