'use client';
import React, { useEffect, useState } from 'react';
import styles from './Loader.module.scss';

interface LoaderProps {
  onAnimationComplete: () => void;
}

const Loader: React.FC<LoaderProps> = ({ onAnimationComplete }) => {
  const [progress, setProgress] = useState(0);
  const [segments, setSegments] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(progressInterval);
          // Defer the completion callback to the next microtask
          void Promise.resolve().then(onAnimationComplete);
          return prev;
        }
      });
    }, 25);

    return () => {
      clearInterval(progressInterval);
    };
  }, [onAnimationComplete]);

  useEffect(() => {
    const numSegments = Math.floor((progress / 100) * 67);
    setSegments((prevSegments) => {
      const newSegments = [];
      for (let i = prevSegments.length; i < numSegments; i++) {
        newSegments.push(
          <div key={i} className={styles['loader__progress-segment']} />,
        );
      }
      return [...prevSegments, ...newSegments];
    });
  }, [progress]);

  return (
    <div className={styles.loader}>
      <div className={styles['loader__progress-container']}>
        <span
          className={styles['loader__progress-text']}
          data-text={`${progress.toString().padStart(2, '0')}%`}
        >
          {progress.toString().padStart(2, '0')}%
        </span>
        <div className={styles['loader__progress-bar']}>
          <div className={styles['loader__progress-bar-segments']}>
            {segments}
          </div>
          <div className={styles['loader__progress-bar-background']}></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
