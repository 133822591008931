import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styles from './Memes.module.scss';
import { memeImages as originalMemeImages } from './memeImages';
import memeTexts from './memes-text-v2.json';
import useMemeActions from '@/hooks/useMemeActions';
import { useAccount } from 'wagmi';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';
import Lottie from 'react-lottie-player';

const ANIMATE_WITH_AI_FEATURE_ENABLED = true;

const shuffleArray = (array: string[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const fetchGifUrl = async (imageName: string): Promise<string | null> => {
  const response = await fetch(
    `/api/get-meme-gif?imageName=${encodeURIComponent(imageName)}`,
  );
  if (!response.ok) {
    console.error('Failed to fetch GIF:', response.statusText);
    return null;
  }

  const data: { gifUrl: string; error?: string } = await response.json();
  if (data.error) {
    console.error('Error fetching GIF:', data.error);
    return null;
  }

  return data.gifUrl;
};

const Memes: React.FC = () => {
  const { address } = useAccount();
  const userAddress = address || null;
  const [memeImages, setMemeImages] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showTextInputs, setShowTextInputs] = useState<boolean>(false);
  const [topText, setTopText] = useState<string>('');
  const [bottomText, setBottomText] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [, setIsTextGeneratedByAI] = useState<boolean>(false);
  const [showGif, setShowGif] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [finalMemeUrl, setFinalMemeUrl] = useState<string | null>(null);
  const [gifUrl, setGifUrl] = useState<string | null>(null);
  const [isGifLoading, setIsGifLoading] = useState(false);

  const { saveMeme, shareOnX, isSaving, isSharing, memeRef, createFinalMeme } =
    useMemeActions(
      memeImages,
      currentIndex,
      topText,
      bottomText,
      userAddress,
      showGif,
    );

  const gifRef = useRef<HTMLImageElement>(null);
  const { track } = useMixpanelTracking();

  useEffect(() => {
    const shuffledImages = shuffleArray(originalMemeImages);
    setMemeImages(shuffledImages);
  }, []);

  useEffect(() => {
    if (step !== 3) {
      setShowGif(false);
    }
  }, [step]);

  const previousImage = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + memeImages.length) % memeImages.length,
    );
    setTopText('');
    setBottomText('');
    void track('Meme Generator - Previous Image', {});
  }, [memeImages, track]);

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % memeImages.length);
    setTopText('');
    setBottomText('');
    void track('Meme Generator - Next Image', {});
  }, [memeImages, track]);

  const randomizeImage = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * memeImages.length);
    setCurrentIndex(randomIndex);
    setTopText('');
    setBottomText('');
    void track('Meme Generator - Randomize Image', {});
  }, [memeImages, track]);

  const getVisibleImages = useMemo(() => {
    const visibleImages = [];
    for (let i = -1; i <= 1; i++) {
      visibleImages.push(
        memeImages[(currentIndex + i + memeImages.length) % memeImages.length],
      );
    }
    return visibleImages;
  }, [memeImages, currentIndex]);

  const generateTextWithAI = useCallback(async () => {
    setIsGenerating(true);
    await new Promise((resolve) => setTimeout(resolve, 1250));
    const currentImage = memeImages[currentIndex]?.split('/').pop();
    if (currentImage) {
      const memeFormat = memeTexts.Memes.MemeFormat.find(
        (meme) => meme.name === currentImage,
      );
      if (memeFormat) {
        const { memes } = memeFormat;
        if (memes.length > 0) {
          const randomMeme = memes[Math.floor(Math.random() * memes.length)];
          const { TopText, BottomText } = randomMeme;
          setTopText(TopText);
          setBottomText(BottomText || '');
          setIsTextGeneratedByAI(true);
          void track('Meme Generator - Generate Text with AI', {
            image: currentImage,
          });
        }
      }
    }
    setIsGenerating(false);
  }, [memeImages, currentIndex, track]);

  const handlePointerEvent = useCallback(
    (action: () => void | Promise<void>, eventName: string) =>
      (event: React.PointerEvent) => {
        event.preventDefault();
        void track(eventName, {});
        const result = action();
        if (result instanceof Promise) {
          void result.catch((error) => {
            console.error('Error in async action:', error);
          });
        }
      },
    [track],
  );

  const toggleGif = useCallback(async () => {
    if (showGif) {
      setShowGif(false);
      return;
    }
    setIsAnimating(true);
    setIsGifLoading(true);
    const imageName = memeImages[currentIndex]
      ?.split('/')
      .pop()
      ?.replace('.webp', '.gif');
    if (imageName) {
      const gifPath = await fetchGifUrl(imageName);
      if (gifPath) {
        const img = new Image();
        img.onload = () => {
          setGifUrl(gifPath);
          setShowGif(true);
          setIsGifLoading(false);
          setIsAnimating(false);
        };
        img.src = gifPath;
      } else {
        setIsGifLoading(false);
        setIsAnimating(false);
      }
    } else {
      setIsGifLoading(false);
      setIsAnimating(false);
    }
  }, [memeImages, currentIndex, showGif]);

  useEffect(() => {}, [isAnimating]);

  const handleCreateMeme = useCallback(async () => {
    const finalMemeUrl = await createFinalMeme();
    setFinalMemeUrl(finalMemeUrl);
    setStep(3);
  }, [createFinalMeme]);

  return (
    <div className={styles['memes__container']}>
      <button
        className={styles['memes__back-button']}
        onPointerDown={handlePointerEvent(() => {
          if (step === 1) {
            setShowTextInputs(!showTextInputs);
            setTopText('');
            setBottomText('');
          } else if (step === 2) {
            setStep(1);
            setTopText('');
            setBottomText('');
          } else {
            setStep(step - 1);
          }
        }, 'Meme Generator - Back Button')}
      >
        {step !== 1 && `◀`}
      </button>
      <div className={styles['memes__border-container']}>
        <div className={styles['memes__image-container']} ref={memeRef}>
          <div className={styles['memes__media-container']}>
            {step === 3 && finalMemeUrl && !showGif ? (
              <img
                src={finalMemeUrl}
                alt='Final Meme'
                className={styles['memes__final-image']}
                loading='lazy'
              />
            ) : (
              <img
                src={memeImages[currentIndex]}
                alt=''
                className={`${styles['memes__main-image']} ${showGif ? styles['memes__hidden'] : ''}`}
                loading='lazy'
              />
            )}
            {isGifLoading ? (
              <div
                className={`${styles['memes__main-image']} ${!showGif ? styles['memes__hidden'] : ''}`}
              >
                Loading...
              </div>
            ) : (
              <img
                ref={gifRef}
                src={gifUrl || ''}
                alt='Meme GIF'
                className={`${styles['memes__main-image']} ${!showGif ? styles['memes__hidden'] : ''}`}
              />
            )}
            {topText && !showGif && step !== 3 && (
              <div className={styles['memes__top-text']}>{topText}</div>
            )}
            {bottomText && !showGif && step !== 3 && (
              <div className={styles['memes__bottom-text']}>{bottomText}</div>
            )}
            {topText && showGif && step !== 3 && (
              <div className={styles['memes__top-text']}>{topText}</div>
            )}
            {bottomText && showGif && step !== 3 && (
              <div className={styles['memes__bottom-text']}>{bottomText}</div>
            )}
          </div>
        </div>
      </div>
      {step === 1 && (
        <>
          <div className={styles['memes__thumbnails-container']}>
            <button
              onPointerDown={handlePointerEvent(
                previousImage,
                'Meme Generator - Previous Image',
              )}
              className={styles['memes__arrow-button']}
            >
              ◀
            </button>
            <div className={styles['memes__thumbnails']}>
              {getVisibleImages.map((image, index) => (
                <div
                  key={index}
                  className={styles['memes__thumbnail']}
                  onPointerDown={handlePointerEvent(() => {
                    setCurrentIndex(
                      (currentIndex + index - 1 + memeImages.length) %
                        memeImages.length,
                    );
                    setTopText('');
                    setBottomText('');
                  }, 'Meme Generator - Select Image')}
                >
                  <img src={image} alt={``} loading='lazy' />
                </div>
              ))}
            </div>
            <button
              onPointerDown={handlePointerEvent(
                nextImage,
                'Meme Generator - Next Image',
              )}
              className={styles['memes__arrow-button']}
            >
              ▶
            </button>
          </div>
          <div className={styles['memes__buttons-container']}>
            <button
              className={styles['memes__randomize-button']}
              onPointerDown={handlePointerEvent(
                randomizeImage,
                'Meme Generator - Randomize Image',
              )}
            >
              Randomize
            </button>
            <button
              className={styles['memes__add-button']}
              onPointerDown={handlePointerEvent(
                () => setStep(2),
                'Meme Generator - Add Text',
              )}
            >
              Add Text
            </button>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className={styles['memes__text-inputs-container']}>
            <input
              type='text'
              placeholder='// Enter your own top line text'
              className={`${styles['memes__text-input']} ${styles['memes__no-zoom']}`}
              value={topText}
              onChange={(e) => {
                setTopText(e.target.value.slice(0, 70));
                void track('Meme Generator - Top Text Input', {
                  value: e.target.value.slice(0, 70),
                });
              }}
              maxLength={70}
            />
            <input
              type='text'
              placeholder='// Enter your own bottom line text'
              className={`${styles['memes__text-input']} ${styles['memes__no-zoom']}`}
              value={bottomText}
              onChange={(e) => {
                setBottomText(e.target.value.slice(0, 70));
                void track('Meme Generator - Bottom Text Input', {
                  value: e.target.value.slice(0, 70),
                });
              }}
              maxLength={70}
            />
            <div className={styles['memes__buttons-generate']}>
              <button
                className={styles['memes__generate-button']}
                onPointerDown={handlePointerEvent(
                  generateTextWithAI,
                  'Meme Generator - Generate Text with AI',
                )}
              >
                {isGenerating ? (
                  <Lottie
                    play
                    loop
                    animationData={require('/public/lottie/loading.json')}
                    className={styles['memes__lottie-animation']}
                  />
                ) : (
                  'Generate with AI'
                )}
              </button>
              <button
                className={styles['memes__create-button']}
                onPointerDown={handlePointerEvent(
                  handleCreateMeme,
                  'Meme Generator - Create Meme',
                )}
              >
                Create Meme
              </button>
            </div>
          </div>
        </>
      )}
      {step === 3 && (
        <div className={styles['memes__text-inputs-container']}>
          {ANIMATE_WITH_AI_FEATURE_ENABLED && (
            <button
              className={styles['memes__animate-button']}
              onPointerDown={handlePointerEvent(
                toggleGif,
                'Meme Generator - Toggle Gif',
              )}
            >
              {isAnimating ? (
                <Lottie
                  play
                  loop
                  animationData={require('/public/lottie/loading.json')}
                  className={styles['memes__lottie-animation']}
                />
              ) : !showGif ? (
                'Animate with AI'
              ) : (
                'Back to Meme Image'
              )}
            </button>
          )}
          <div className={styles['memes__action-buttons']}>
            <button
              className={styles['memes__save-button']}
              onPointerDown={handlePointerEvent(
                saveMeme,
                'Meme Generator - Save Meme',
              )}
            >
              Save Meme
              {isSaving && (
                <img
                  src='/loaders/button-loading.gif'
                  alt='Loading'
                  className={styles['memes__loading-spinner']}
                />
              )}
            </button>
            <button
              className={styles['memes__share-button']}
              onPointerDown={handlePointerEvent(
                shareOnX,
                'Meme Generator - Share on X',
              )}
            >
              Share on X
              {isSharing && (
                <img
                  src='/loaders/button-loading.gif'
                  alt='Loading'
                  className={styles['memes__loading-spinner']}
                />
              )}
            </button>
          </div>
          <button
            className={styles['memes__create-another-button']}
            onPointerDown={handlePointerEvent(() => {
              setStep(1);
              setTopText('');
              setBottomText('');
              setShowGif(false);
            }, 'Meme Generator - Create Another Meme')}
          >
            Create Another Meme
          </button>
        </div>
      )}
    </div>
  );
};

export default Memes;
