'use client';
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './Nft.module.scss';
import TerminalHeader from '../terminal-header/TerminalHeader';

const NFT: React.FC = () => {
  return (
    <div className={styles.nft}>
      <TerminalHeader />
      <h2 className={styles['nft__title']}></h2>

      <p className={styles['nft__paragraph']}>
        {`> `}Want to be an early insidooooorrrrrr? 'Day-one' Chads will be
        rewarded with NFTs that look rare and put them on the fast track to
        Valhalla. Follow the community updates closely to see how you can work
        your way into the cabal.
      </p>
      <p className={styles['nft__paragraph']}>
        {`> `}The ChadOS Floppy Disk gives early access to Chad Bot and the
        benefits that come with being an early user like airdrops, referral
        rewards, and pooled trading fees.
      </p>

      <p className={styles['nft__paragraph']}>
        {`> `}Only the most loyal community contributors will earn the right to
        flex the WGMIDIA NFTs, unlocking access to staking multipliers and first
        $GPU tokens.
      </p>
      <ul className={styles['nft__list']}>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['nft__list-icon']}
          />
          ChadAI ChadOS Floppy Disk - Open Edition
        </li>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['nft__list-icon']}
          />
          ChadAI x WGMIDIA Muscle Memory A69 QPU x 69
        </li>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['nft__list-icon']}
          />
          ChadAI x WGMIDIA GigaChad A420 GPU x 420
        </li>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['nft__list-icon']}
          />
          ChadAI x WGMIDIA Dark Matter 6900 CPU x 511
        </li>
      </ul>
    </div>
  );
};

export default NFT;
