// src/hooks/use-door-state.ts

import { useEffect } from 'react';
import { useDoorContext } from '@/context/DoorContext';

const useDoorState = (setIsModalVisible: (visible: boolean) => void) => {
  const { isKillAIPressed, isKillAIReleased, setIsDoorOpen } = useDoorContext();

  useEffect(() => {
    if (isKillAIReleased) {
      setIsDoorOpen(true);
      setIsModalVisible(true);
    } else if (isKillAIPressed) {
      setIsDoorOpen(false);
      setIsModalVisible(false);
    }
  }, [isKillAIPressed, isKillAIReleased, setIsDoorOpen, setIsModalVisible]);
};

export default useDoorState;
