// src/app/components/music-player/MusicPlayer.tsx

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import Image from 'next/image';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import styles from './MusicPlayer.module.scss';
import useSounds from '@/hooks/useSounds';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';
import { fetchMusicData } from 'app/actions/music';

gsap.registerPlugin(Draggable);

type Track = {
  name: string;
};

type Genre = {
  name: string;
};

const MusicPlayer: React.FC = () => {
  const [playerImage, setPlayerImage] = useState('/player/player-1.webp');
  const [playerImage5] = useState('/player/player-5.webp');
  const [genres, setGenres] = useState<Genre[]>([]);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [currentGenreIndex, setCurrentGenreIndex] = useState(0);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(
    'Chadify Radio by ChadAI - Press Play',
  );
  const [volume, setVolume] = useState(0.5);
  const [, setRotation] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const { playSound } = useSounds();
  const { track } = useMixpanelTracking();

  // Memoized helper function to replace underscores with spaces
  const formatTrackName = useMemo(() => {
    return (name: string) => name.replace(/_/g, ' ').replace('.mp3', '');
  }, []);

  useEffect(() => {
    async function loadInitialData() {
      const genreData = await fetchMusicData();
      if (genreData && genreData.length > 0) {
        setGenres(genreData);
        await fetchTracks(0);
      }
    }
    void loadInitialData();
  }, []);

  const fetchTracks = useCallback(
    async (genreIndex: number): Promise<void> => {
      const genre = genres[genreIndex];
      if (!genre) return;

      const genreName = genre.name;
      try {
        const trackData = await fetchMusicData(genreName);
        setTracks(trackData);
        setCurrentTrackIndex(0);
        setIsInitialized(true);
      } catch (error) {
        console.error('Error fetching tracks:', error);
      }
    },
    [genres],
  );

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
      const initialRotation = volume * 360;
      setRotation(initialRotation);
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current && tracks[currentTrackIndex]) {
      const currentTrack = tracks[currentTrackIndex];
      const currentGenre = genres[currentGenreIndex]?.name;
      const audioUrl = `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/music-player/${encodeURIComponent(currentGenre)}/${currentTrack.name}`;
      audioRef.current.src = audioUrl;
      if (isPlaying) {
        void audioRef.current.play();
        setDisplayMessage(
          `${currentGenre} - ${formatTrackName(currentTrack.name)}`,
        );
      }
    }
  }, [
    currentTrackIndex,
    isPlaying,
    tracks,
    genres,
    currentGenreIndex,
    formatTrackName,
  ]);

  const handlePlayPausePointerDown = useCallback(() => {
    setPlayerImage('/player/player-2.webp');
    void track('Music - Button Pressed', { button: 'Play/Pause' });
  }, [track]);

  const handlePlayPausePointerUp = useCallback(() => {
    setPlayerImage('/player/player-1.webp');
    if (!isInitialized) {
      void fetchTracks(currentGenreIndex).then(() => playTrack());
    } else {
      playTrack();
    }
    playSound('musicPlayerButton');
  }, [isInitialized, currentGenreIndex, fetchTracks, playSound]);

  const playTrack = useCallback(() => {
    if (audioRef.current) {
      const currentTrack = tracks[currentTrackIndex];
      if (!isPlaying && currentTrack) {
        const currentGenre = genres[currentGenreIndex]?.name;
        const audioUrl = `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/music-player/${encodeURIComponent(currentGenre)}/${currentTrack.name}`;
        audioRef.current.src = audioUrl;
        void audioRef.current.play();
        setDisplayMessage(
          `${currentGenre} - ${formatTrackName(currentTrack.name)}`,
        );
      } else if (isPlaying) {
        audioRef.current.pause();
        setDisplayMessage('ChadAI Radio Mix - Press Play');
      }
      setIsPlaying(!isPlaying);
    }
  }, [
    tracks,
    currentTrackIndex,
    isPlaying,
    genres,
    currentGenreIndex,
    formatTrackName,
  ]);

  const handleNextPointerDown = useCallback(() => {
    setPlayerImage('/player/player-4.webp');
    void track('Music - Button Pressed', { button: 'Next' });
  }, [track]);

  const handleNextPointerUp = useCallback(() => {
    void handleNextTrack();
    playSound('musicPlayerButton');
  }, [playSound]);

  const handleNextTrack = useCallback(async () => {
    setPlayerImage('/player/player-1.webp');
    let newTrackIndex = currentTrackIndex + 1;
    if (newTrackIndex >= tracks.length) {
      newTrackIndex = 0;
      const newGenreIndex = (currentGenreIndex + 1) % genres.length;
      setCurrentGenreIndex(newGenreIndex);
      await fetchTracks(newGenreIndex);
    }
    setCurrentTrackIndex(newTrackIndex);
    if (audioRef.current && tracks[newTrackIndex]) {
      const nextTrack = tracks[newTrackIndex];
      const currentGenre = genres[currentGenreIndex]?.name;
      const audioUrl = `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/music-player/${encodeURIComponent(currentGenre)}/${nextTrack.name}`;
      audioRef.current.src = audioUrl;
      audioRef.current.load();
      if (isPlaying) {
        setDisplayMessage(
          `${currentGenre} - ${formatTrackName(nextTrack.name)}`,
        );
        void audioRef.current.play();
      }
    }
  }, [
    currentTrackIndex,
    tracks,
    currentGenreIndex,
    genres,
    fetchTracks,
    isPlaying,
    formatTrackName,
  ]);

  const handleGenrePointerDown = useCallback(() => {
    setPlayerImage('/player/player-3.webp');
    void track('Music - Button Pressed', { button: 'Genre' });
  }, [track]);

  const handleGenrePointerUp = useCallback(() => {
    void handleGenreChange();
    playSound('musicPlayerButton');
  }, [playSound]);

  const handleGenreChange = useCallback(async () => {
    setPlayerImage('/player/player-1.webp');
    const newGenreIndex = (currentGenreIndex + 1) % genres.length;
    setCurrentGenreIndex(newGenreIndex);
    await fetchTracks(newGenreIndex);
    if (audioRef.current && tracks[0]) {
      const firstTrack = tracks[0];
      const currentGenre = genres[newGenreIndex]?.name;
      const audioUrl = `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/music-player/${encodeURIComponent(currentGenre)}/${firstTrack.name}`;
      audioRef.current.src = audioUrl;
      audioRef.current.load();
      if (isPlaying) {
        setDisplayMessage(
          `${currentGenre} - ${formatTrackName(firstTrack.name)}`,
        );
        void audioRef.current.play();
      }
    }
  }, [
    currentGenreIndex,
    genres,
    fetchTracks,
    tracks,
    isPlaying,
    formatTrackName,
  ]);

  useEffect(() => {
    if (imgRef.current) {
      Draggable.create(imgRef.current, {
        type: 'rotation',
        inertia: true,
        onDrag: function (this: Draggable) {
          const rotationValue: number = this.rotation;
          setRotation(rotationValue);
          adjustVolume(rotationValue);
        },
      });
    }
  }, []);

  const adjustVolume = useCallback((rotation: number) => {
    const clampedRotation = Math.max(0, Math.min(rotation, 360));
    let newVolume = clampedRotation / 360;
    newVolume = Math.max(0, Math.min(1, newVolume));
    setVolume(newVolume);

    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  }, []);

  return (
    <div className={styles.musicPlayer}>
      <Image
        src={playerImage}
        alt='Music Player'
        className={styles.playerImage}
        width={500}
        height={300}
        priority
      />
      <div className={styles.screen}>
        <div className={styles.marquee}>
          <div>{displayMessage}</div>
        </div>
      </div>
      <div
        className={styles.playPauseButton}
        onPointerDown={handlePlayPausePointerDown}
        onPointerUp={handlePlayPausePointerUp}
        aria-label={isPlaying ? 'Pause' : 'Play'}
        role='button'
        tabIndex={0}
      />
      <div
        className={styles.nextButton}
        onPointerDown={handleNextPointerDown}
        onPointerUp={handleNextPointerUp}
        aria-label='Next Track'
        role='button'
        tabIndex={0}
      />
      <div
        className={styles.genreButton}
        onPointerDown={handleGenrePointerDown}
        onPointerUp={handleGenrePointerUp}
        aria-label='Next Genre'
        role='button'
        tabIndex={0}
      />
      <div className={styles.player5Image}>
        <Image
          ref={imgRef}
          src={playerImage5}
          alt='Dial'
          width={100}
          height={100}
        />
      </div>
      <audio ref={audioRef} />
    </div>
  );
};

export default MusicPlayer;
