// src/app/components/center/WalletSection.tsx

import React from 'react';
import styles from './Center.module.scss';

interface WalletSectionProps {
  walletButtonImage: string;
  handleWalletButtonPress: () => void;
  playSound: (
    sound:
      | 'buttonPress'
      | 'buttonRelease'
      | 'killAI'
      | 'killAI3'
      | 'brokenKeys'
      | 'powerOn'
      | 'musicPlayerButton',
  ) => void;
}

const WalletSection: React.FC<WalletSectionProps> = ({
  walletButtonImage,
  handleWalletButtonPress,
  playSound,
}) => {
  return (
    <div className={styles['center__wallet-row']}>
      <img
        src='/terminal-keys/floppy-socket.webp'
        alt='Floppy Socket'
        className={styles['center__floppy-socket']}
        draggable='false'
      />
      <button
        className={styles['center__connect-wallet-button']}
        onPointerDown={handleWalletButtonPress}
        onPointerUp={() => playSound('buttonPress')}
      >
        <img src={walletButtonImage} alt='Connect Wallet' draggable='false' />
      </button>
    </div>
  );
};

export default WalletSection;
