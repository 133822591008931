export const memeImages = [
  '/memes/ansem-boxing.webp',
  '/memes/anthony-adams-rubbing-hands.webp',
  '/memes/arnold-predator-handshake.webp',
  '/memes/bad-luck-brian.webp',
  '/memes/ben-affleck-smoking.webp',
  '/memes/but-that-is-none-of-my-business.webp',
  '/memes/chad-galaxy-brain.webp',
  '/memes/chad-yelling-at-party.webp',
  '/memes/change-my-mind.webp',
  '/memes/confused-nick-young.webp',
  '/memes/crying-michael-jordan.webp',
  '/memes/crypto-bro-explaining.webp',
  '/memes/daily-struggle-two-buttons.webp',
  '/memes/disappointed-fan.webp',
  '/memes/disaster-girl.webp',
  '/memes/distracted-boyfriend.webp',
  '/memes/drake-hotline-bling.webp',
  '/memes/elon-lion-king.webp',
  '/memes/elon-smoking.webp',
  '/memes/evil-kermit.webp',
  '/memes/for-the-better-right.webp',
  '/memes/futurama-fry.webp',
  '/memes/giga-brain-chad.webp',
  '/memes/goodfellas-laughing.webp',
  '/memes/green-wojak-hug.webp',
  '/memes/guy-blinking.webp',
  '/memes/he-bought-bizonacci.webp',
  '/memes/hide-the-pain-harold.webp',
  '/memes/homer-simpson-bushes.webp',
  '/memes/in-it-for-the-tech.webp',
  '/memes/i-bet-he-is-thinking-about-other-women.webp',
  '/memes/is-it-a-pigeon.webp',
  '/memes/jason-mamoa-superman.webp',
  '/memes/kanye-advice.webp',
  '/memes/keanu-buried.webp',
  '/memes/kevin-james-smirking.webp',
  '/memes/left-exit-12-off-ramp.webp',
  '/memes/leo-biting-fist.webp',
  '/memes/leo-cheers.webp',
  '/memes/leonardo-dicaprio-laughing.webp',
  '/memes/mcdonalds-wojak.webp',
  '/memes/nobody-knows-party.webp',
  '/memes/one-does-not-simply.webp',
  '/memes/pablo-escobar-waiting.webp',
  '/memes/roll-safe.webp',
  '/memes/sad-keanu.webp',
  '/memes/salt-bae.webp',
  '/memes/sam-altman-hearing.webp',
  '/memes/scumbag-steve.webp',
  '/memes/shutup-and-take-my-money.webp',
  '/memes/skeptical-third-world-kid.webp',
  '/memes/spider-man-pointing-at-spider-man.webp',
  '/memes/success-kid.webp',
  '/memes/taking-profits.webp',
  '/memes/the-hearing-thingsoooor.webp',
  '/memes/the-most-interesting-man-in-the-world.webp',
  '/memes/this-is-fine.webp',
  '/memes/toy-story-everywhere.webp',
  '/memes/trump-mugshot.webp',
  '/memes/trump-yelling.webp',
  '/memes/unpopular-opinion-swords.webp',
  '/memes/what-i-told-you.webp',
  '/memes/willy-wonka-condescending.webp',
  '/memes/wojak-angel-devil.webp',
  '/memes/wojak-crying-mask.webp',
  '/memes/wojak-crying-vs-chad.webp',
  '/memes/woman-yelling-at-a-cat.webp',
  '/memes/young-thug-computer.webp',
  '/memes/zuck-creeping.webp'
];


export const memeGifs = [
  '/memes/animated/ansem-boxing.gif',
  '/memes/animated/anthony-adams-rubbing-hands.gif',
  '/memes/animated/arnold-predator-handshake.gif',
  '/memes/animated/bad-luck-brian.gif',
  '/memes/animated/ben-affleck-smoking.gif',
  '/memes/animated/but-that-is-none-of-my-business.gif',
  '/memes/animated/chad-galaxy-brain.gif',
  '/memes/animated/chad-yelling-at-party.gif',
  '/memes/animated/change-my-mind.gif',
  '/memes/animated/confused-nick-young.gif',
  '/memes/animated/crying-michael-jordan.gif',
  '/memes/animated/crypto-bro-explaining.gif',
  '/memes/animated/daily-struggle-two-buttons.gif',
  '/memes/animated/disappointed-fan.gif',
  '/memes/animated/disaster-girl.gif',
  '/memes/animated/distracted-boyfriend.gif',
  '/memes/animated/drake-hotline-bling.gif',
  '/memes/animated/elon-lion-king.gif',
  '/memes/animated/elon-smoking.gif',
  '/memes/animated/evil-kermit.gif',
  '/memes/animated/for-the-better-right.gif',
  '/memes/animated/futurama-fry.gif',
  '/memes/animated/giga-brain-chad.gif',
  '/memes/animated/goodfellas-laughing.gif',
  '/memes/animated/green-wojak-hug.gif',
  '/memes/animated/guy-blinking.gif',
  '/memes/animated/he-bought-bizonacci.gif',
  '/memes/animated/hide-the-pain-harold.gif',
  '/memes/animated/homer-simpson-bushes.gif',
  '/memes/animated/in-it-for-the-tech.gif',
  '/memes/animated/i-bet-he-is-thinking-about-other-women.gif',
  '/memes/animated/is-it-a-pigeon.gif',
  '/memes/animated/jason-mamoa-superman.gif',
  '/memes/animated/kanye-advice.gif',
  '/memes/animated/keanu-buried.gif',
  '/memes/animated/kevin-james-smirking.gif',
  '/memes/animated/left-exit-12-off-ramp.gif',
  '/memes/animated/leo-biting-fist.gif',
  '/memes/animated/leo-cheers.gif',
  '/memes/animated/leonardo-dicaprio-laughing.gif',
  '/memes/animated/mcdonalds-wojak.gif',
  '/memes/animated/nobody-knows-party.gif',
  '/memes/animated/one-does-not-simply.gif',
  '/memes/animated/pablo-escobar-waiting.gif',
  '/memes/animated/roll-safe.gif',
  '/memes/animated/sad-keanu.gif',
  '/memes/animated/salt-bae.gif',
  '/memes/animated/sam-altman-hearing.gif',
  '/memes/animated/scumbag-steve.gif',
  '/memes/animated/shutup-and-take-my-money.gif',
  '/memes/animated/skeptical-third-world-kid.gif',
  '/memes/animated/spider-man-pointing-at-spider-man.gif',
  '/memes/animated/success-kid.gif',
  '/memes/animated/taking-profits.gif',
  '/memes/animated/the-hearing-thingsoooor.gif',
  '/memes/animated/the-most-interesting-man-in-the-world.gif',
  '/memes/animated/this-is-fine.gif',
  '/memes/animated/toy-story-everywhere.gif',
  '/memes/animated/trump-mugshot.gif',
  '/memes/animated/trump-yelling.gif',
  '/memes/animated/unpopular-opinion-swords.gif',
  '/memes/animated/what-i-told-you.gif',
  '/memes/animated/willy-wonka-condescending.gif',
  '/memes/animated/wojak-angel-devil.gif',
  '/memes/animated/wojak-crying-mask.gif',
  '/memes/animated/wojak-crying-vs-chad.gif',
  '/memes/animated/woman-yelling-at-a-cat.gif',
  '/memes/animated/young-thug-computer.gif',
  '/memes/animated/zuck-creeping.gif'
];