// src/components/modal/GlassMorphicModal.tsx

'use client';

import React, { useEffect, useRef, useState } from 'react';
import styles from './GlassMorphicModal.module.scss';
import { useDoorContext } from '@/context/DoorContext';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';

interface GlassMorphicModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const GlassMorphicModal: React.FC<GlassMorphicModalProps> = ({
  isVisible,
  onClose,
}) => {
  const { setIsDoorOpen } = useDoorContext();
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const [delayedVisible, setDelayedVisible] = useState(false);
  const { track } = useMixpanelTracking();

  const handleButtonClick = () => {
    setIsDoorOpen(false);
    onClose();
    void track('GlassMorphicModal Button Clicked', {});
  };

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setDelayedVisible(true);
      }, 3500); // Delay visibility by 2 seconds
    } else {
      setDelayedVisible(false);
    }
  }, [isVisible]);

  if (!delayedVisible) {
    return null;
  }

  return (
    <div
      className={`${styles.modal__overlay} ${delayedVisible ? styles['modal__overlay--visible'] : ''}`}
    >
      <div
        className={styles.modal__content}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 ref={titleRef}>Kill AI</h2>
        <p>
          What if there was even a small chance AI could kill us? Even if we
          cared, could we do anything about it?
        </p>
        <p>
          Some of the smartest and most influential tech entrepreneurs were so
          concerned about the existential risks of AI that they created a
          non-profit foundation to serve as a backstop for humanity. Today that
          organization is the most powerful and centralized force in the entire
          industry.
        </p>
        <h3>What can we do?</h3>
        <p>
          By nature, AI is centralizing. It puts the collective soon-to-be
          superintelligence of humanity in the hands of a powerful few. A
          blackbox of data, biases, and truths.
        </p>
        <p>
          Blockchains are inherently decentralizing. They allow for data to be
          distributed and openly shared in a verifiable way previously not
          possible.
        </p>
        <p>
          Supporting ChadAI and other opensource, decentralized crypto AI
          projects brings us one step closer to democratizing our collective
          intelligence for a better world. But most importantly, to help us all
          be a bigger Chad.
        </p>
        <div className={styles['button-container']}>
          <button
            onClick={handleButtonClick}
            className={styles['custom-button']}
          >
            Back to ChadAI
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlassMorphicModal;
