// src/components/SnakeGame/HighScores.tsx
'use client';

import React, { useEffect, useState } from 'react';
import styles from './HighScores.module.scss';
import SnakeLoader from '@/components/loader/SnakeLoader';

interface HighScore {
  address: string;
  score: number;
}

const HighScores: React.FC = () => {
  const [highScores, setHighScores] = useState<HighScore[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHighScores = async (): Promise<void> => {
      try {
        const response = await fetch(
          '/api/game/snake/get-high-scores?gameName=Snake',
          {
            headers: {
              'x-api-key': process.env.NEXT_PUBLIC_API_KEY || '',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch high scores');
        }

        const data = (await response.json()) as HighScore[];
        setHighScores(data);
      } catch (error) {
        console.error('Error fetching high scores:', error);
        setError('Failed to fetch high scores');
      } finally {
        setLoading(false);
      }
    };

    fetchHighScores().catch((error) => {
      console.error('Error in fetchHighScores:', error);
      setError('Failed to fetch high scores');
    });
  }, []);

  const formatAddress = (address: string) => {
    if (address.length <= 8) return address;
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  return (
    <div className={styles.highScoresContainer}>
      <h2>Chad Scores</h2>
      {loading ? (
        <SnakeLoader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {highScores.map((score, index) => (
            <li key={index}>
              {formatAddress(score.address)}: {score.score}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HighScores;
