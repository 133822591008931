import { useRef, useEffect } from 'react';

interface Sounds {
  buttonPressSoundRef: React.RefObject<HTMLAudioElement | null>;
  buttonReleaseSoundRef: React.RefObject<HTMLAudioElement | null>;
  killAISoundRef: React.RefObject<HTMLAudioElement | null>;
  killAISound3Ref: React.RefObject<HTMLAudioElement | null>;
  brokenKeysSoundRef: React.RefObject<HTMLAudioElement | null>;
  powerButtonOnSoundRef: React.RefObject<HTMLAudioElement | null>;
  musicPlayerButtonSoundRef: React.RefObject<HTMLAudioElement | null>;
  playSound: (sound: 'buttonPress' | 'buttonRelease' | 'killAI' | 'killAI3' | 'brokenKeys' | 'powerOn' | 'musicPlayerButton') => void;
}

const useSounds = (): Sounds => {
  const buttonPressSoundRef = useRef<HTMLAudioElement | null>(null);
  const buttonReleaseSoundRef = useRef<HTMLAudioElement | null>(null);
  const killAISoundRef = useRef<HTMLAudioElement | null>(null);
  const killAISound3Ref = useRef<HTMLAudioElement | null>(null);
  const brokenKeysSoundRef = useRef<HTMLAudioElement | null>(null);
  const powerButtonOnSoundRef = useRef<HTMLAudioElement | null>(null);
  const musicPlayerButtonSoundRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    buttonPressSoundRef.current = new Audio('/sounds/plastic_keyboard.wav');
    buttonReleaseSoundRef.current = new Audio('/sounds/plastic_keyboard.wav');
    killAISoundRef.current = new Audio('/sounds/killai1.mp3');
    killAISound3Ref.current = new Audio('/sounds/killai3.mp3');
    brokenKeysSoundRef.current = new Audio('/sounds/broken_keys.wav');
    powerButtonOnSoundRef.current = new Audio('/sounds/boot_on_sound.mp3');
    musicPlayerButtonSoundRef.current = new Audio('/sounds/music-player-buttons-a.wav');

    if (killAISoundRef.current && killAISound3Ref.current) {
      killAISoundRef.current.addEventListener('ended', () => {
        killAISound3Ref.current!.currentTime = 0;
        killAISound3Ref.current!.play().catch((error) => {
          console.error('Error playing sound:', error);
        });
      });
    }
  }, []);

  const playSound = (sound: 'buttonPress' | 'buttonRelease' | 'killAI' | 'killAI3' | 'brokenKeys' | 'powerOn' | 'musicPlayerButton') => {
    let soundRef: HTMLAudioElement | null = null;

    switch (sound) {
      case 'buttonPress':
        soundRef = buttonPressSoundRef.current;
        break;
      case 'buttonRelease':
        soundRef = buttonReleaseSoundRef.current;
        break;
      case 'killAI':
        soundRef = killAISoundRef.current;
        break;
      case 'killAI3':
        soundRef = killAISound3Ref.current;
        break;
      case 'brokenKeys':
        soundRef = brokenKeysSoundRef.current;
        break;
      case 'powerOn':
        soundRef = powerButtonOnSoundRef.current;
        break;
      case 'musicPlayerButton':
        soundRef = musicPlayerButtonSoundRef.current;
        break;
    }

    if (soundRef) {
      soundRef.currentTime = 0;
      soundRef.play().catch((error) => {
        console.error('Error playing sound:', error);
      });
    }
  };

  return {
    buttonPressSoundRef,
    buttonReleaseSoundRef,
    killAISoundRef,
    killAISound3Ref,
    brokenKeysSoundRef,
    powerButtonOnSoundRef,
    musicPlayerButtonSoundRef,
    playSound,
  };
};

export default useSounds;
