// src/app/actions/music.ts

'use server';

import { supabaseAdmin } from '@/lib/supabase-client';

export async function fetchMusicData(
  genreName?: string,
  limit = 20,
  offset = 0,
) {
  try {
    if (genreName) {
      const { data: tracks, error: tracksError } = await supabaseAdmin.storage
        .from('music-player')
        .list(genreName, {
          limit,
          offset,
          sortBy: { column: 'name', order: 'asc' },
        });

      if (tracksError) {
        throw new Error(tracksError.message);
      }

      if (!tracks) {
        throw new Error('No tracks found');
      }

      return tracks.map((track) => ({
        name: track.name,
        url: `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/music-player/${encodeURIComponent(genreName)}/${track.name}`,
      }));
    } else {
      const { data: genresData, error: genresError } =
        await supabaseAdmin.storage.from('music-player').list('', {
          limit,
          offset,
          sortBy: { column: 'created_at', order: 'desc' },
        });

      if (genresError) {
        throw new Error(genresError.message);
      }

      if (!genresData) {
        throw new Error('No genres found');
      }

      return genresData.map((genre) => ({ name: genre.name }));
    }
  } catch (error) {
    throw new Error(
      error instanceof Error ? error.message : 'An unexpected error occurred',
    );
  }
}
