// src/app/components/center/Center.tsx

'use client';

import React, { useEffect, useCallback, useState } from 'react';

import { useUIContext } from '@/context/UIContext';

import useMediaQuery from '@/utils/use-media-query';

import Screen from '@/components/screen/Screen';
import ComingSoon from '@/views/coming-soon/ComingSoon';

import useWalletHandling from '@/hooks/useWalletHandling';
import useKeyboardAndTouchEvents from '@/hooks/useKeyboardAndTouchEvents';
import useKillAI from '@/hooks/useKillAI';

import ControlButtons from './ControlButtons';
import ChatBotButtons from './ChatBotButtons';
import WalletSection from './WalletSection';
import KillAISection from './KillAISection';

import styles from './Center.module.scss';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';

interface CenterProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Center: React.FC<CenterProps> = ({ setIsModalVisible }) => {
  const {
    isLoading,
    whatsappIcon,
    telegramIcon,
    discordIcon,
    homeIcon,
    stakingIcon,
    tokenIcon,
    memesIcon,
    nftIcon,
    networkIcon,
    shopIcon,
    daoIcon,
    pressButton,
    isPowerOn,
    screenContent,
    setScreenContent,
    togglePowerButton,
  } = useUIContext();

  const {
    walletButtonImage,
    handleWalletButtonPress,
    playSound,
    address,
    status,
  } = useWalletHandling(isPowerOn);
  useKeyboardAndTouchEvents();
  const { handleKillAIClick, isKillAIPressed } = useKillAI(setIsModalVisible);
  const { track } = useMixpanelTracking();
  const [, setKey] = useState(0);

  const isSmallScreen = useMediaQuery('(max-width: 1023px)');

  const handleMouseDown = useCallback(
    (button: string, content: React.ReactNode) => {
      if (isPowerOn && !isLoading) {
        pressButton(button);
        setScreenContent(content);
        playSound(
          button === 'network' ||
            button === 'dao' ||
            button === 'shop' ||
            button === 'whatsapp' ||
            button === 'telegram' ||
            button === 'discord'
            ? 'brokenKeys'
            : 'buttonPress',
        );
      }
    },
    [isPowerOn, isLoading, pressButton, setScreenContent, playSound],
  );

  useEffect(() => {
    setScreenContent(<ComingSoon />);
  }, [setScreenContent]);

  const handleButtonPress = useCallback(
    (
      action: () => void,
      buttonName: string,
    ): ((
      event: React.PointerEvent | React.TouchEvent | React.MouseEvent,
    ) => void) => {
      return (
        event: React.PointerEvent | React.TouchEvent | React.MouseEvent,
      ) => {
        event.preventDefault();

        if (!isPowerOn) {
          // If the terminal is off, turn it on
          togglePowerButton();
        } else {
          // If the terminal is on, execute the button's action
          action();
        }

        playSound(
          buttonName === 'network' ||
            buttonName === 'dao' ||
            buttonName === 'shop' ||
            buttonName === 'whatsapp' ||
            buttonName === 'telegram' ||
            buttonName === 'discord'
            ? 'brokenKeys'
            : 'buttonPress',
        );

        void trackButtonPress(buttonName);
      };
    },
    [playSound, address, status, isPowerOn, togglePowerButton],
  );
  const trackButtonPress = useCallback(
    async (buttonName: string) => {
      try {
        await track('Button Pressed', {
          button: buttonName,
          isPowerOn,
          wallet_connected: status === 'connected',
        });
      } catch (error) {
        console.error('Error tracking button press:', error);
      }
    },
    [track, isPowerOn, status],
  );
  useEffect(() => {
    if (status === 'connected') {
      setKey((prevKey) => prevKey + 1);
    }
  }, [status]);

  return (
    <div className={styles['center__container']}>
      <div className={styles['center__tv-frame']}>
        <img
          src='/terminal-keys/frame.webp'
          className={styles['center__frame-image']}
          alt='TV Frame'
          draggable='false'
        />
        <Screen
          content={
            isLoading ? (
              <div className={styles['center__loading-spinner']} />
            ) : (
              screenContent
            )
          }
        />
      </div>
      <WalletSection
        walletButtonImage={walletButtonImage}
        handleWalletButtonPress={() => handleWalletButtonPress(() => {})}
        playSound={playSound}
      />
      <ChatBotButtons
        handleButtonPress={handleButtonPress}
        handleMouseDown={handleMouseDown}
        icons={{ whatsappIcon, telegramIcon, discordIcon }}
      />
      <div className={styles['center__controls-row']}>
        {isSmallScreen && (
          <img
            className={styles['center__controls-background']}
            src='/backgrounds/bottom-frame-v9.webp'
            alt='background'
          />
        )}
        <ControlButtons
          handleButtonPress={handleButtonPress}
          handleMouseDown={handleMouseDown}
          icons={{
            homeIcon,
            tokenIcon,
            memesIcon,
            nftIcon,
            stakingIcon,
            networkIcon,
            daoIcon,
            shopIcon,
          }}
        />
      </div>
      <KillAISection
        handleKillAIClick={handleKillAIClick}
        isKillAIPressed={isKillAIPressed}
        handleButtonPress={handleButtonPress}
      />
    </div>
  );
};

export default Center;
