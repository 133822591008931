// src/app/components/center/KillAISection.tsx

import React from 'react';
import styles from './Center.module.scss';

interface KillAISectionProps {
  handleKillAIClick: () => void;
  isKillAIPressed: boolean;
  handleButtonPress: (
    action: () => void,
    buttonName: string,
  ) => (
    event: React.PointerEvent | React.TouchEvent | React.MouseEvent,
  ) => void;
}

const KillAISection: React.FC<KillAISectionProps> = ({
  handleKillAIClick,
  isKillAIPressed,
  handleButtonPress,
}) => {
  return (
    <div className={styles['center__kill-ai-container']}>
      <button
        className={styles['center__kill-ai-button']}
        onPointerDown={handleButtonPress(handleKillAIClick, 'Kill_AI')}
      >
        <img
          src={
            isKillAIPressed
              ? '/terminal-keys/kill-ai-pressed.webp'
              : '/terminal-keys/kill-ai-rest.webp'
          }
          alt='Kill AI button'
          draggable='false'
        />
      </button>
      <img
        src='/terminal-keys/kill-ai-notice.webp'
        alt='kill ai notice'
        className={styles['center__kill-ai-sticker']}
        draggable='false'
      />
    </div>
  );
};

export default KillAISection;
