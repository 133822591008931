'use client';

import React, { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import styles from './Footer.module.scss';
import MusicPlayer from '@/components/music-player/MusicPlayer';
import mixpanel from '@/lib/mixpanel';
import { useUIContext } from '@/context/UIContext';
import { useAccount } from 'wagmi';
import SnakeGame from '@/views/snake-game/SnakeGame';
import useMediaQuery from '@/utils/use-media-query';
import useKillAI from '@/hooks/useKillAI';
import { useDoorContext } from '@/context/DoorContext';
import Lottie from 'react-lottie-player';

const Footer: React.FC = () => {
  const isSmallScreen = useMediaQuery('(max-width: 1023px)');
  const { setScreenContent } = useUIContext();
  const [, setIsModalVisible] = useState(false);
  const { status } = useAccount();

  const killAISoundRef = useRef<HTMLAudioElement | null>(null);
  const killAISound3Ref = useRef<HTMLAudioElement | null>(null);

  const { handleKillAIClick } = useKillAI(setIsModalVisible);
  const { isKillAIPressed } = useDoorContext();

  useEffect(() => {
    killAISoundRef.current = new Audio('/sounds/killai1.mp3');
    killAISound3Ref.current = new Audio('/sounds/killai3.mp3');

    if (killAISoundRef.current && killAISound3Ref.current) {
      killAISoundRef.current.addEventListener('ended', () => {
        if (killAISound3Ref.current) {
          killAISound3Ref.current.currentTime = 0;
          killAISound3Ref.current.play().catch((error) => {
            console.error('Error playing killAISound3:', error);
          });
        }
      });
    }
  }, []);

  const handleSnakeGameClick = () => {
    if (status === 'connected') {
      setScreenContent(<SnakeGame />);
      mixpanel.track('Sticker - Clicked Base Logo ');
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles['footer__brain']}>
        <img
          src='/terminal-keys/brain.webp'
          alt='Brain'
          className={styles['footer__brain-image']}
        />
        <Lottie
          play
          loop
          animationData={require('/public/lottie/brain.json')}
          className={styles['footer__brain-animation']}
        />
      </div>
      <div>
        <Image
          src='/terminal-keys/sticker-3-middle.webp'
          alt='dark matter'
          className={styles['footer__sticker-dark-matter']}
          width={135}
          height={135}
          sizes='(max-width: 1023px) 135px, (min-width: 1024px) 275px, (min-width: 1400px) 300px, (min-width: 1900px) 350px'
          quality={100}
          priority
        />
      </div>
      <div className={styles['footer__sticker-container']}>
        <div className={styles['footer__sticker-container-left']}>
          <Image
            src='/terminal-keys/sticker-2-left.webp'
            alt='chad'
            className={styles['footer__sticker-chad']}
            width={125}
            height={125}
            sizes='(max-width: 1023px) 125px, (min-width: 1024px) 184px, (min-width: 1400px) 200px, (min-width: 1900px) 300px'
            quality={100}
            priority
          />
          <Image
            src='/terminal-keys/sticker-4-left.webp'
            alt='ai-dumb'
            className={styles['footer__sticker-ai-dumb']}
            width={225}
            height={225}
            sizes='(max-width: 1023px) 225px, (min-width: 1024px) 375px, (min-width: 1400px) 400px, (min-width: 1900px) 350px'
            quality={100}
            priority
          />
        </div>
        <div className={styles['footer__sticker-container-middle']}>
          <div className={styles['footer__sticker-container-kill']}>
            <button
              className={styles['footer__kill-ai']}
              onPointerDown={handleKillAIClick}
            >
              <Image
                src={
                  isKillAIPressed
                    ? '/terminal-keys/kill-ai-pressed.webp'
                    : '/terminal-keys/kill-ai-rest.webp'
                }
                alt='kill AI'
                width={150}
                height={150}
                sizes='(max-width: 1023px) 150px, (min-width: 1024px) 0'
                quality={100}
                priority
              />
            </button>

            <Image
              src='/terminal-keys/kill-ai-notice.webp'
              alt='kill ai notice'
              className={styles['footer__kill-sticker']}
              width={100}
              height={100}
              sizes='(max-width: 1023px) 100px, (min-width: 1024px) 0'
              quality={100}
              priority
            />
          </div>
        </div>
        <div className={styles['footer__sticker-container-right']}>
          <Image
            src='/terminal-keys/sticker-8-right.webp'
            alt='borderlands header'
            className={styles['footer__sticker-borderlands-header']}
            width={100}
            height={100}
            sizes='(max-width: 1023px) 100px, (min-width: 1024px) 200px, (min-width: 1900px) 350px'
            onPointerDown={handleSnakeGameClick}
            quality={100}
            priority
          />
          <Image
            src='/terminal-keys/sticker-7-right.webp'
            alt='red text'
            className={styles['footer__sticker-red-text']}
            width={100}
            height={100}
            sizes='(max-width: 1023px) 100px, (min-width: 1024px) 200px, (min-width: 1900px) 275px'
            quality={100}
            priority
          />
          <Image
            src='/terminal-keys/sticker-6-right.webp'
            alt='snake game'
            className={styles['footer__sticker-ball']}
            width={75}
            height={75}
            sizes='(max-width: 1023px) 75px, (min-width: 1024px) 125px, (min-width: 1900px) 175px'
            onPointerDown={handleSnakeGameClick}
            quality={100}
            priority
          />
          <Image
            src='/terminal-keys/sticker-1-right.webp'
            alt='poster'
            className={styles['footer__sticker-poster']}
            width={150}
            height={150}
            sizes='(max-width: 1023px) 150px, (min-width: 1024px) 200px, (min-width: 1900px) 275px'
            quality={100}
            priority
          />
        </div>
      </div>
      {isSmallScreen && <MusicPlayer />}
    </footer>
  );
};

export default Footer;
