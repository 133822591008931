'use client';
import React from 'react';
import styles from './Screen.module.scss';

interface ScreenProps {
  content: React.ReactNode;
}

const Screen: React.FC<ScreenProps> = ({ content }) => {
  return (
    <div className={styles.tvScreen}>
      <div className={styles.screenOverlay}>
        <img src={'/terminal-keys/screen-overlay.webp'} alt='Overlay' />
      </div>
      {content}
      <div className={styles.glitchOverlay}></div>
    </div>
  );
};

export default Screen;
