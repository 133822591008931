// src/hooks/use-track-page-load.ts

/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';
import { getCookie } from '@/utils/cookies';
import UAParser from 'ua-parser-js';
import axios from 'axios';

const useTrackPageLoad = (
  userIp: string | null,
  track: any,
  updateProfile: any,
) => {
  useEffect(() => {
    const trackPageLoad = async () => {
      let mixpanelId = getCookie('mixpanel_id');
      const userAgent = window.navigator.userAgent;
      const parser = new UAParser(userAgent);
      const result = parser.getResult();

      if (!mixpanelId) {
        try {
          const response = await axios.get('/api/mixpanel/identify');
          mixpanelId = response.data.mixpanelId;

          await updateProfile({
            $first_visit: new Date().toISOString(),
            $initial_referrer: document.referrer,
            $initial_referring_domain: document.referrer
              ? new URL(document.referrer).hostname
              : '',
            $browser: result.browser.name || '',
            $browser_version: result.browser.version || '',
            $device: result.device.model || 'Unknown',
            $os: result.os.name || '',
            $os_version: result.os.version || '',
            ip: userIp || '',
          });
        } catch (error) {
          console.error('Error identifying user:', error);
        }
      }

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const utmProperties = [
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_content',
          'utm_term',
        ].reduce((acc: Record<string, string>, key: string) => {
          const value = urlParams.get(key);
          if (value) {
            acc[key] = value;
          }
          return acc;
        }, {});

        await track('Page Loaded', {
          page: window.location.pathname,
          $browser: result.browser.name || '',
          $device: result.device.model || 'Unknown',
          $os: result.os.name || '',
          $referrer: document.referrer || '',
          $referring_domain: document.referrer
            ? new URL(document.referrer).hostname
            : '',
          ...utmProperties,
          ip: userIp || '',
        });
      } catch (error) {
        console.error('Error tracking page load:', error);
      }
    };

    if (userIp) {
      void trackPageLoad();
    }
  }, [track, updateProfile, userIp]);
};

export default useTrackPageLoad;
