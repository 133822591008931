// src/app/components/center/ControlButtons.tsx

import React from 'react';
import styles from './Center.module.scss';
import Home from '@/views/home/Home';
import Token from '@/views/token/Token';
import Memes from '@/views/memes/Memes';
import NFT from '@/views/nft/Nft';
import Staking from '@/views/staking/Staking';
import ComingSoonCountdown from '@/views/standby-screen/ComingSoonCountdown';

interface ControlButtonsProps {
  handleButtonPress: (
    action: () => void,
    buttonName: string,
  ) => (
    event: React.PointerEvent | React.TouchEvent | React.MouseEvent,
  ) => void;
  handleMouseDown: (button: string, content: React.ReactNode) => void;
  icons: {
    homeIcon: string;
    tokenIcon: string;
    memesIcon: string;
    nftIcon: string;
    stakingIcon: string;
    networkIcon: string;
    daoIcon: string;
    shopIcon: string;
  };
}

const ControlButtons: React.FC<ControlButtonsProps> = ({
  handleButtonPress,
  handleMouseDown,
  icons,
}) => {
  return (
    <div className={styles['center__controls-container']}>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('home', <Home />),
          'home',
        )}
      >
        <img src={icons.homeIcon} alt='Home' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('token', <Token />),
          'token',
        )}
      >
        <img src={icons.tokenIcon} alt='Token' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('memes', <Memes />),
          'memes',
        )}
      >
        <img src={icons.memesIcon} alt='Memes' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('nft', <NFT />),
          'nft',
        )}
      >
        <img src={icons.nftIcon} alt='NFT' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('staking', <Staking />),
          'staking',
        )}
      >
        <img src={icons.stakingIcon} alt='Staking' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('network', <ComingSoonCountdown />),
          'network',
        )}
      >
        <img src={icons.networkIcon} alt='Network' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('dao', <ComingSoonCountdown />),
          'dao',
        )}
      >
        <img src={icons.daoIcon} alt='DAO' draggable='false' />
      </button>
      <button
        className={styles['center__control-button']}
        onPointerDown={handleButtonPress(
          () => handleMouseDown('shop', <ComingSoonCountdown />),
          'shop',
        )}
      >
        <img src={icons.shopIcon} alt='Shop' draggable='false' />
      </button>
    </div>
  );
};

export default ControlButtons;
