/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styles from './ComingSoonCountdown.module.scss';

const ComingSoonCountdown: React.FC = () => {
  return (
    <div className={styles['coming-soon__container']}>
      {/* <div className={styles['coming-soon__text-top']}>
        // ChadOS v0.69 ChadAI Foundation
      </div> */}
      <div className={styles['coming-soon__text']}>COMING SOON</div>
    </div>
  );
};

export default ComingSoonCountdown;
