'use client';

import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './SnakeGame.module.scss';
import HighScores from './HighScores';
import { useAccount } from 'wagmi';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';

const SnakeGame: React.FC = () => {
  const { address } = useAccount();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [snake, setSnake] = useState([{ x: 10, y: 10 }]);
  const [food, setFood] = useState({ x: 15, y: 15 });
  const [direction, setDirection] = useState('RIGHT');
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [score, setScore] = useState(0);
  const [speed, setSpeed] = useState(100); // New state for speed
  const [showHighScores, setShowHighScores] = useState(false);
  const [gameOverMessage, setGameOverMessage] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const [canvasSize, setCanvasSize] = useState(135);
  const [tileSize, setTileSize] = useState(5);

  const { track } = useMixpanelTracking();

  useEffect(() => {
    const updateCanvasSize = () => {
      if (window.innerWidth >= 1900) {
        setCanvasSize(450);
        setTileSize(15);
      } else if (window.innerWidth >= 1400) {
        setCanvasSize(350);
        setTileSize(10);
      } else if (window.innerWidth >= 1023) {
        setCanvasSize(285);
        setTileSize(7);
      } else {
        setCanvasSize(135);
        setTileSize(5);
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          if (direction !== 'DOWN') setDirection('UP');
          break;
        case 'ArrowDown':
          if (direction !== 'UP') setDirection('DOWN');
          break;
        case 'ArrowLeft':
          if (direction !== 'RIGHT') setDirection('LEFT');
          break;
        case 'ArrowRight':
          if (direction !== 'LEFT') setDirection('RIGHT');
          break;
      }
    };

    const preventArrowScroll = (event: KeyboardEvent) => {
      if (
        ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keydown', preventArrowScroll);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keydown', preventArrowScroll);
    };
  }, [direction]);

  const handleDirectionChange = useCallback(
    (newDirection: string) => {
      if (
        (newDirection === 'UP' && direction !== 'DOWN') ||
        (newDirection === 'DOWN' && direction !== 'UP') ||
        (newDirection === 'LEFT' && direction !== 'RIGHT') ||
        (newDirection === 'RIGHT' && direction !== 'LEFT')
      ) {
        setDirection(newDirection);
      }
    },
    [direction],
  );

  const resetGame = useCallback(() => {
    setSnake([{ x: 10, y: 10 }]);
    setFood({ x: 15, y: 15 });
    setDirection('RIGHT');
    setScore(0);
    setSpeed(100); // Reset speed
    setIsGameRunning(true);
    setGameOverMessage(null);
    void track('Snake Game Started', {});
  }, [track]);

  const saveScore = useCallback(
    async (score: number) => {
      setIsSaving(true);
      try {
        const response = await fetch('/api/game/snake/save-scores', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY || '',
          },
          body: JSON.stringify({
            address,
            score,
            gameName: 'Snake',
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to save score');
        }
        void track('Snake Game Score Saved', { score });
      } catch (error) {
        console.error('Error saving score:', error);
      } finally {
        setIsSaving(false);
      }
    },
    [address, track],
  );

  const moveSnake = useCallback(async () => {
    if (!isGameRunning) return;
    const newSnake = [...snake];
    const head = { ...newSnake[0] };

    switch (direction) {
      case 'UP':
        head.y -= 1;
        break;
      case 'DOWN':
        head.y += 1;
        break;
      case 'LEFT':
        head.x -= 1;
        break;
      case 'RIGHT':
        head.x += 1;
        break;
    }

    newSnake.unshift(head);
    if (head.x === food.x && head.y === food.y) {
      setFood({
        x: Math.floor((Math.random() * canvasSize) / tileSize),
        y: Math.floor((Math.random() * canvasSize) / tileSize),
      });
      setScore(score + 1);
      setSpeed((prevSpeed) => Math.max(prevSpeed - 5, 50)); // Increase speed with each bite
    } else {
      newSnake.pop();
    }

    if (
      head.x < 0 ||
      head.x >= canvasSize / tileSize ||
      head.y < 0 ||
      head.y >= canvasSize / tileSize ||
      newSnake
        .slice(1)
        .some((segment) => segment.x === head.x && segment.y === head.y)
    ) {
      setIsGameRunning(false);
      setGameOverMessage('Game Over');
      void track('Snake Game Over', { score });
      try {
        await saveScore(score);
      } catch (error) {
        console.error('Error handling game over:', error);
      } finally {
        setTimeout(() => {
          setGameOverMessage(null);
        }, 3000);
      }
      return;
    }

    setSnake(newSnake);
  }, [
    snake,
    food,
    direction,
    isGameRunning,
    score,
    canvasSize,
    tileSize,
    track,
    saveScore,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      void moveSnake();
    }, speed);
    return () => clearInterval(interval);
  }, [moveSnake, speed]);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      context.clearRect(0, 0, canvasSize, canvasSize);
      context.fillStyle = 'green';
      snake.forEach((segment) => {
        context.fillRect(
          segment.x * tileSize,
          segment.y * tileSize,
          tileSize,
          tileSize,
        );
      });
      context.fillStyle = 'red';
      context.fillRect(
        food.x * tileSize,
        food.y * tileSize,
        tileSize,
        tileSize,
      );

      if (gameOverMessage) {
        context.fillStyle = 'var(--primary-color)';
        context.font = '20px VT323';
        context.textAlign = 'center';
        context.fillText(gameOverMessage, canvasSize / 2, canvasSize / 2);
      }
    }
  }, [snake, food, gameOverMessage, canvasSize, tileSize]);

  return (
    <div className={styles.gameContainer}>
      <div className={styles.buttonContainer}>
        {!showHighScores && (
          <>
            <button
              className={`${styles.startButton} ${isGameRunning ? styles.active : ''}`}
              onClick={resetGame}
              disabled={isSaving || isGameRunning}
            >
              {isSaving ? 'Saving game' : isGameRunning ? 'Playing' : 'Start'}
            </button>
            <div className={styles.score}>{score}</div>
          </>
        )}
        <button
          className={`${styles.tabButton} ${!showHighScores ? styles.active : ''}`}
          onClick={() => {
            setShowHighScores(false);
            void track('Snake Game Tab Viewed', { tab: 'Game' });
          }}
        >
          Game
        </button>
        <button
          className={`${styles.tabButton} ${showHighScores ? styles.active : ''}`}
          onClick={() => {
            setShowHighScores(true);
            void track('Snake Game Tab Viewed', { tab: 'High Scores' });
          }}
        >
          High Scores
        </button>
      </div>
      {showHighScores ? (
        <HighScores />
      ) : (
        <>
          <canvas
            ref={canvasRef}
            className={styles.canvas}
            width={canvasSize}
            height={canvasSize}
          />

          <div className={styles.gamePad}>
            <button onClick={() => handleDirectionChange('UP')}>↑</button>
            <div>
              <button
                className={styles.left}
                onClick={() => handleDirectionChange('LEFT')}
              >
                ←
              </button>
              <button
                className={styles.right}
                onClick={() => handleDirectionChange('RIGHT')}
              >
                →
              </button>
            </div>
            <button onClick={() => handleDirectionChange('DOWN')}>↓</button>
          </div>
        </>
      )}
    </div>
  );
};

export default SnakeGame;
