/* eslint-disable react/no-unescaped-entities */
'use client';

import React from 'react';
import styles from './WhatsappBot.module.scss';
import TerminalHeader from '@/views/terminal-header/TerminalHeader';
import { useUIContext } from '@/context/UIContext';
import NFT from '@/views/nft/Nft';

const WhatsappBot: React.FC = () => {
  const { setActiveButton, setNftIcon, setStakingIcon, setScreenContent } =
    useUIContext();

  const handleNftLinkClick = () => {
    setScreenContent(<NFT />);
    setActiveButton('nft');
    setNftIcon('/terminal-keys/nft-pressed.webp');
    setStakingIcon('/terminal-keys/staking-rest.webp');
  };

  return (
    <div className={styles.whatsapp}>
      <TerminalHeader />
      <h2 className={styles['whatsapp__title']}></h2>
      <p className={styles['whatsapp__paragraph']}>
        {`> `}Chad Bot is the only crypto trading bot running on the ChadGPT
        Degenerative AI, utilizing all 420 trillion parameters of the Chad
        Language Model (CLM) sourced from conversations, social sentiment, and
        real-time omni-chain market data.
      </p>
      <p className={styles['whatsapp__paragraph']}>
        {`> `}Just say 'Hey Chad' and ask any crypto question or automagically
        put any trade into action across your favourite blockchains. Stay on top
        of news, alerts, onchain events, and get summaries of all the giga brain
        alpha in your chat groups in one place.
      </p>
      <p className={styles['whatsapp__paragraph']}>
        {`> `}Being early on Chad Bot means being early on rewards. Get early
        access to rewards for training Chad Bot's neural network, staking,
        referring frens, making trades, and hodl $CHADAI to share in the pooled
        fees with your fellow Chads.
      </p>
      <p className={styles['whatsapp__paragraph']}>
        {`> `}Want to invite Chad Bot to your favourite chad group? Learn more
        about early access{' '}
        <a
          href='#NFT'
          className={styles['whatsapp__link']}
          onClick={handleNftLinkClick}
        >
          HERE
        </a>
      </p>
    </div>
  );
};

export default WhatsappBot;
