'use client';
import React, { useState, useCallback } from 'react';
import Image from 'next/image';

import { useUIContext } from '@/context/UIContext';
import useMediaQuery from '@/utils/use-media-query';
import useSounds from '@/hooks/useSounds';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';

import MusicPlayer from '@/components/music-player/MusicPlayer';

import styles from './Header.module.scss';

const Header: React.FC = () => {
  const isSmallScreen = useMediaQuery('(max-width: 1023px)');
  const [tgIcon, setTgIcon] = useState(
    '/terminal-keys/telegram-social-rest.webp',
  );
  const [xIcon, setXIcon] = useState('/terminal-keys/x-social-rest.webp');
  const { powerIcon, togglePowerButton, isPowerOn } = useUIContext();
  const { playSound } = useSounds();
  const { track } = useMixpanelTracking();

  const handleButtonPress = useCallback(
    (
      setIconState: React.Dispatch<React.SetStateAction<string>>,
      pressedSrc: string,
      restSrc: string,
      buttonName: string,
      action?: () => void,
    ) =>
      (event: React.PointerEvent | React.TouchEvent | React.MouseEvent) => {
        event.preventDefault();
        setIconState(pressedSrc);
        playSound('buttonPress');

        void track('Header - Button Pressed', { button: buttonName });

        setTimeout(() => {
          setIconState(restSrc);
          if (action) {
            action();
          }
        }, 100);
      },
    [playSound, track],
  );

  const handlePowerButtonClick = useCallback(() => {
    togglePowerButton();
    if (!isPowerOn) {
      playSound('powerOn');
    }
    void track('Power Button Clicked', { new_state: !isPowerOn });
  }, [togglePowerButton, isPowerOn, playSound, track]);

  const openTwitter = useCallback(() => {
    window.open('https://twitter.com/ChadAIdotorg', '_blank');
    void track('Twitter Opened', {});
  }, [track]);

  return (
    <header className={styles.header}>
      <div className={styles['header__logo-container']}>
        <button
          className={styles['header__power-button']}
          onPointerDown={(event) => {
            event.preventDefault();
            playSound('buttonPress');
          }}
          onPointerUp={(event) => {
            event.preventDefault();
            handlePowerButtonClick();
          }}
        >
          <Image
            src={powerIcon}
            alt='Power Icon'
            draggable='false'
            width={50}
            height={50}
            className={styles.icon}
            priority
            sizes='(max-width: 1023px) 50px, (min-width: 1024px) 65px, (min-width: 1400px) 70px, (min-width: 1900px) 80px'
          />
        </button>
      </div>
      {!isSmallScreen && (
        <div className={styles['header__music-player']}>
          <MusicPlayer />
        </div>
      )}
      <div className={styles['header__buttons']}>
        <button
          className={styles['header__button']}
          onPointerDown={handleButtonPress(
            setTgIcon,
            '/terminal-keys/telegram-social-pressed.webp',
            '/terminal-keys/telegram-social-rest.webp',
            'Telegram',
            () => {
              window.open('https://t.me/+ZXE5DPh5lbRmODE1', '_blank');
              void track('Telegram Opened', {});
            },
          )}
        >
          <Image
            src={tgIcon}
            alt='Telegram Icon'
            draggable='false'
            width={45}
            height={45}
            className={styles.icon}
            priority
            quality={100}
            sizes='(max-width: 1023px) 45px, (min-width: 1024px) 55px, (min-width: 1400px) 60px, (min-width: 1900px) 70px'
          />
        </button>

        <button
          className={styles['header__button']}
          onPointerDown={handleButtonPress(
            setXIcon,
            '/terminal-keys/x-social-pressed.webp',
            '/terminal-keys/x-social-rest.webp',
            'X',
            openTwitter,
          )}
        >
          <Image
            src={xIcon}
            alt='X Icon'
            draggable='false'
            width={45}
            height={45}
            className={styles.icon}
            priority
            sizes='(max-width: 1023px) 45px, (min-width: 1024px) 55px, (min-width: 1400px) 60px, (min-width: 1900px) 70px'
          />
        </button>
        <Image
          src={'/terminal-keys/sticker-5-top.webp'}
          alt='Chad Sticker'
          width={100}
          height={100}
          className={`${styles.icon} ${styles['header__logo']}`}
          priority
          sizes='(max-width: 1023px) 100px, (min-width: 1024px) 100px, (min-width: 1400px) 150px, (min-width: 1900px) 200px'
        />
      </div>
    </header>
  );
};

export default Header;
