// lib/mixpanel.ts
import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

mixpanel.init(MIXPANEL_TOKEN!, {
  debug: process.env.NODE_ENV !== 'production', 
  ignore_dnt: true 
});

export default mixpanel;
