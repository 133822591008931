// src/app/views/token/Token.tsx

'use client';
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './Token.module.scss';
import TerminalHeader from '../terminal-header/TerminalHeader';

const Token: React.FC = () => {
  return (
    <div className={styles.token}>
      <TerminalHeader />
      <h2 className={styles['token__title']}></h2>
      <p className={styles['token__paragraph']}>
        {`> `}$CHADAI is the official deflationary reserve currency of Chads
        around the world and backed by a strategic reserve of 100% real, dank
        memes.
      </p>
      <p className={styles['token__paragraph']}>
        {`> `}Just as tokens in AI models represent units of information, each
        $CHADAI token symbolizes 1,000 parameters in the Chad Language Model.
      </p>
      <p className={styles['token__paragraph']}>$CHADAI is for:</p>
      <ul className={styles['token__list']}>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['token__list-icon']}
          />
          Prompt Mining rewards for training the Chad Language Model
        </li>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['token__list-icon']}
          />
          Referral fee rewards for Chad Bot users
        </li>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['token__list-icon']}
          />
          Paying for Chad Bot trading fees and premium features
        </li>
        <li>
          <img
            src={'/icons/right-arrow.png'}
            alt='arrow'
            className={styles['token__list-icon']}
          />
          Staking to earn rewards
        </li>
      </ul>
    </div>
  );
};

export default Token;
